import * as _shim2 from "use-sync-external-store/shim";
var _shim = _shim2;
try {
  if ("default" in _shim2) _shim = _shim2.default;
} catch (e) {}
var exports = {};
var b = _shim;
exports.useSubscription = function (a) {
  return b.useSyncExternalStore(a.subscribe, a.getCurrentValue);
};
export default exports;